import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface TrackerState
{
    allowCookies: boolean;
}

const initialState: TrackerState = {
    allowCookies: false
};

export const trackerSlice = createSlice({
    name: 'tracker',
    initialState,
    reducers: {
        setAllowCookies: (state, action: PayloadAction<boolean>) => {
            state.allowCookies = action.payload;
        }
    }
});

export const { setAllowCookies } = trackerSlice.actions;

export default trackerSlice.reducer;