import './../public/css/style.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { store } from '@redux/store';
import Script from 'next/script';
import config from '@config';

function NextApp({ Component, pageProps }: AppProps)
{
    return (
        <Provider store={store}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href={config.defaultPublicDirBaseUrl + 'favicon.ico'}  />
            </Head>
            <Component {...pageProps} />

            <Script src={config.defaultPublicDirBaseUrl + 'lib/bootstrap/popper.min.js'} />
            <Script src={config.defaultPublicDirBaseUrl + 'lib/bootstrap/bootstrap.min.js'} />
        </Provider>
    )
}

export default NextApp
